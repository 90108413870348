// Required for resolving img files in template
import.meta.glob([
  '../../img/**',
]);

import '/@front:css/style.css'

import { App, compile, createApp } from 'vue'

import { registerComponents } from './components'
import Logger from './logger'
import { registerMixins } from './mixins'
import { registerPlugins } from './plugins'

export default class Application {
  private logger!: Logger

  constructor(component: any) {
    this.bindListeners()
    this.loadIcons()
    this.initVue(component)
  }

  private initVue(component: any) {
    const el = document.querySelector('#front-app') as HTMLDivElement

    const template = el.innerHTML
    const renderFunc = compile(template)
    component.render = renderFunc

    const app = createApp(component)

    registerComponents(app)
    registerPlugins(app)
    registerMixins(app)

    this.initLogger(app)
    app.mount(el)
  }

  private initLogger(app: App) {
    this.logger = new Logger()

    app.config.errorHandler = (err) => {
      if (err instanceof Error) {
        this.logger.addErrorRecord(err)
      }

      if (import.meta.env.DEV) {
        throw err
      }
    }
  }

  private async loadIcons() {
    const { default: icons } = await import('../../img/icons.svg?raw')

    const div = document.createElement('div')
    const body = document.body
    const cssString =
      'border: 0; clip: rect(0 0 0 0); height: 0; overflow: hidden; padding: 0; position: absolute; width: 0;'

    div.style.cssText = cssString
    div.innerHTML = icons
    body.insertBefore(div, body.childNodes[0])
  }

  private bindListeners() {
    window.addEventListener('orientationchange', this.vhFix)
    window.addEventListener('resize', this.vhFix)
  }

  private vhFix() {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }
}
