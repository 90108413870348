import { App, defineAsyncComponent } from 'vue'

export const registerComponents = (app: App) => {
  const modules = import.meta.glob('../components/*.vue')

  for (const path in modules) {
    if (path) {
      const mod = modules[path]
      const parts = path.split('/')
      const name = parts[parts.length - 1].split('.')[0]

      app.component(name, defineAsyncComponent(mod as any))
    }
  }
}
